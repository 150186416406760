import { useState } from 'react'

import { useSearchTerm } from 'clients/geocoder'
import { useBoolean } from 'hooks'

// TODO: move this to SWR when we convert that file to TS
type Place = {
    id: string
    name: string
    latitude: number
    longitude: number
}

export const useMapGeocoder = (inputRef: React.RefObject<HTMLInputElement>) => {
    const [term, setTerm] = useState('')
    const [active, activate, deactivate] = useBoolean(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const results = useSearchTerm(term)

    const onFocus = () => {
        if (!Boolean(term)) {
            activate()
        }
    }

    const onKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        selectHandler: (place: Place) => void
    ) => {
        // Down
        if (event.keyCode === 40) {
            event.preventDefault()
            setSelectedIndex(calculateSelectedIndex(selectedIndex, results.data.length, true))
        }

        // Up
        if (event.keyCode === 38) {
            event.preventDefault()
            setSelectedIndex(calculateSelectedIndex(selectedIndex, results.data.length, false))
        }

        // Enter
        if (event.keyCode === 13) {
            event.preventDefault()
            if (Boolean(term)) {
                selectHandler(results.data[selectedIndex])
                deactivate()
                setSelectedIndex(0)
            }
        }

        // Escape
        if (event.keyCode === 27) {
            deactivate()
            inputRef.current?.blur()
        }
    }

    return {
        active,
        term,
        results,
        selectedIndex,
        setTerm,
        activate,
        deactivate,
        onFocus,
        onKeyDown,
    }
}

// Helpers
export const calculateSelectedIndex = (currentIndex: number, max: number, add = true) => {
    let newIndex = currentIndex + 1 * (add ? 1 : -1)

    // Set to 0 or max if out of bounds
    if (newIndex > max - 1) {
        newIndex = 0
    }
    if (newIndex < 0) {
        newIndex = max - 1
    }
    return newIndex
}
