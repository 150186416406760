import { create } from 'zustand'

type State = {
    returnFocusId: string | null
    geolocation: Geolocation | null
}

type Action = {
    setReturnFocusId: (returnFocusId: State['returnFocusId']) => void
    setGeolocation: (geolocation: State['geolocation']) => void
}

const MapStore = create<State & Action>(set => ({
    returnFocusId: null,
    geolocation: null,

    setReturnFocusId: focus => {
        set({ returnFocusId: focus })
    },
    setGeolocation: geolocation => {
        set({ geolocation })
    },
}))

export const useReturnFocusId = () => MapStore(state => state.returnFocusId)
export const useSetReturnFocusId = () => MapStore(state => state.setReturnFocusId)
export const useGeolocation = () => MapStore(state => state.geolocation)
export const useSetGeolocation = () => MapStore(state => state.setGeolocation)
